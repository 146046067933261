export type Auth0User = {
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  name: string;
  nickname: string;
  picture: string;
  sub: string;
  locale: string;
  updated_at: string;
};

export enum OccupancyStatus {
  IN_CONSTRUCTION = 'Vacant - In Construction',
  VACANT_READY = 'Vacant - Ready',
  VACANT_NOT_READY = 'Vacant - Not Ready',
  ON_NOTICE = 'On Notice',
  NA = 'NA',
  VA = 'VA',
}
